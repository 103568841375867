<template>
  <div class="flex flex-col">
    <img class="relative top-16 right-4 w-36 z-10" src="../../assets/images/home.svg" alt="">
    <div class="relative m-auto mx-6 mt-28 z-10 splash">
      <div class="not-access">
        <h6 class="title-addEstate">ثبت آگهی</h6>
        <span class="not-access__description">
          آگهی شما با موفقیت بروز رسانی شد
        </span>
        <div class="not-access__button">
          <router-link :to="{name : 'HomeRealEstate'}" class="btn-custom">
            برگشت به خانه
            <span class="svg-btn-icon2 bg-orange transform">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 24.826) rotate(-90)" fill="#fafafa"/>
</svg>

        </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastStepEditEstate",
  mounted() {
    this.$store.commit('general/CHANGE_IS_SHOW_BACK' , false)
  }
}
</script>

<style scoped>

</style>